import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import FieldValidationError from "../FieldError/FieldError";

interface WYSIWYGEditorProps {
  fieldName: string;
  label?: string;
  register: (options: { name: string }) => void;
  setValue: (name: string, value: string, shouldValidate?: boolean) => void;
  errors?: NestDataObject<any, FieldError>;
  currentValue?: string;
  size?: "small" | "medium" | "large";
  initialValue?: string;
  placeholder?: string;
}

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "color",
  "background",
  "link",
  "image",
  "video",
];

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: ["small", "default", "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};

const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = ({
  fieldName,
  label,
  register,
  setValue,
  errors,
  initialValue = "",
  placeholder = "",
  size = "medium",
}) => {
  useEffect(() => {
    register({ name: fieldName });
  }, [register, fieldName]);

  const handleChange = (html: string) => {
    setValue(fieldName, html, true);
  };

  return (
    <div className={`wysiwyg-editor wysiwyg-editor--${size}`}>
      {label && (
        <label htmlFor={fieldName} className="wysiwyg-editor__label">
          {label}
        </label>
      )}

      <ReactQuill
        theme="snow"
        defaultValue={initialValue}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        bounds=".app"
        placeholder={placeholder}
      />

      {errors?.[fieldName] && <FieldValidationError error={errors[fieldName]?.message || "Invalid input"} />}
    </div>
  );
};

export default WYSIWYGEditor;
